import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

import loadingGif from "../../videos/loadingVimeo.gif";

const StyledDiv = styled.div`
	.modal {
		/* Hidden by default */
		position: fixed; /* Stay in place */
		z-index: 100000; /* Sit on top */
		left: 0;
		top: 0;
		width: 100%; /* Full width */
		height: 100%; /* Full height */
		background-color: rgb(0, 0, 0); /* Fallback color */
		background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
		

		.modal-content-Sub {
			#gifLoaderCheck {
				position: absolute;
				top: 40%;
				right: 45%;
			}
			margin: auto;
			padding: 50px 20px;
			width: 60%;
			margin-top: 6vh;

			h2 {
				line-height: 1.2;
			}

			.publicGetStarted{
				width: 50%;
				margin: auto;
			}

			.form-submission {
				background-color: #fefefe;
				margin: auto;
				padding: 20px;
				border: 1px solid #888;
				width: auto;
				box-shadow: 0px 0px 80px 20px black;

				#vimeo-small-iframe {
					display: none;
				}
			}

			/* The Close Button */
			.closeNew {
				color: #aaaaaa;
				font-size: 35px;
				font-weight: bold;

				&:hover,
				&:focus {
					color: #000;
					text-decoration: none;
					cursor: pointer;
				}
			}
		}

		p {
			text-align: left;
			font-size: 18px;
			font: normal;
			padding: 7px;
			width: 90%;
		}
	}

	input[type="text"],
	input[type="password"],
	select,
	textarea,
	input[type="tel"] {
		width: 100%;
		padding: 7px;
		border: 1px solid #ccc;
		border-radius: 4px;
		resize: vertical;
		font-size: 18px;
	}

	.submitbuttons{
		width: 10vw;
		height: 3vw;
		cursor: pointer;
		border: none;
		outline: none;
		background: #44b2dd 0% 0% no-repeat padding-box;
		border-radius: 4px;
		font: normal normal normal 1vw/1vw Nexa-bold;
		color: #ffffff;
	}

	input[type="submit"] {
		width: 10vw;
		height: 3vw;
		cursor: pointer;
		border: none;
		outline: none;
		background: #44b2dd 0% 0% no-repeat padding-box;
		border-radius: 4px;
		font: normal normal normal 1vw/1vw Nexa-bold;
		color: #ffffff;
	}

	@media only screen and (max-width: 1000px) {
		.modal {
			input[type="submit"] {
				width: 35vw;
				height: 7vw;
				cursor: pointer;
				border: none;
				outline: none;
				background: #44b2dd 0% 0% no-repeat padding-box;
				border-radius: 4px;
				font: normal normal normal Nexa-bold;
				color: #ffffff;
				font-size: 15px;
			}

			.modal-content-Sub {
				position: relative;
				margin: auto;
				padding: 5px;
				width: 95% !important;
				margin-top: 12vh;

				.form-submission {
					background-color: #fefefe;
					margin: auto;
					padding: 10px;
					border: 1px solid #888;
					width: 95%;
					box-shadow: 0px 0px 80px 20px black;
				}
			}

			input[type="text"],
			input[type="password"],
			select,
			textarea,
			input[type="tel"] {
				width: 100%;
				padding: 4px;
				border: 1px solid #ccc;
				border-radius: 4px;
				resize: vertical;
				font-size: 16px;
			}

			p {
				text-align: left;
				font-size: 16px;
				font: normal;
				padding: 4px;
			}
		}
	}
`;

const SubmissionModal = ({ setIsSubModalOpen }) => {
	// Close the modal if the user clicks outside of it
	window.onclick = function (e) {
		if (
			e.target.firstChild.className === "modal-content-Sub" ||
			e.target.firstChild.className === "closeNew" ||
			e.target.children[1].className === "form-submission"
		) {
			setIsSubModalOpen(false);
		}
		flushValues();
	};

	const [submission, setSubmission] = useState({
		country: "US",
		action: "entype",
		inenrollorder: "PAT",
		mtype: "2",
		isoneiro: "",
		setflag: "N",
		setflagname: "apexww",
		setform: "kuvera_enrollment_forretail_step1.html",
		language: "EN",
	});

	const [gifLoad, setLoaderGif] = useState("none");
	const [doneEmail, setDoneEmail] = useState(false);
	const [doneCountry, setDoneCountry] = useState(false);
	const [doneCity, setDoneCity] = useState(false);
	const [donePhone, setDonePhone] = useState(false);
	const [doneTime, setDoneTime] = useState(false);
	const [doneHeard, setDoneHeard] = useState(false);
	const [doneName, setDoneName] = useState(false);
	const [CountValues, addToValues] = useState(0);
	const [publicEnroll_link, newCountry] = useState("https://shield.igenius.biz/join.dhtml?action=entype&sponsor=475398&language=EN&setform=kuvera_enrollment_forretail_step1.html&setflagname=apexww&setflag=N&mtype=2&inenrollorder=PAT&country=US");
	const [submissionPublic, setSubmissionPublic] = useState({
		name: "",
		email: "",
		countryTwo: "",
		city: "",
		phone: "",
		time: "",
		heardAbout: "",
	});

	function SetAllisDone() {
		setDoneEmail(false);
		setDoneCountry(false);
		setDoneCity(false);
		setDonePhone(false);
		setDoneTime(false);
		setDoneHeard(false);
		setDoneName(false);
	}

	function flushValues() {
		//console.log('empty values');
		setSubmissionPublic({
			name: " ",
			email: " ",
			countryTwo: " ",
			city: " ",
			phone: " ",
			time: " ",
			heardAbout: " ",
		});
		var inputs = document.getElementsByTagName("input");
		for (var x of inputs) {
			console.log(x.type);
			if (x.type === "text") {
				x.style.backgroundColor = "white";
			}
		}
		//console.log(submissionPublic);
	}

	function changeName(e) {
		const nameCheck = /(^[A-Za-z ]+$)/;
		if (nameCheck.test(e.target.value)) {
			if (!doneName) {
				addToValues(CountValues + 1);
				setDoneName(true);
				//console.log(CountValues);
				//console.log(doneName);
			}
			console.log(CountValues);
			e.target.style.backgroundColor = "lightgreen";
		} else if (e.target.value === "") {
			e.target.style.backgroundColor = "white";
		} else {
			e.target.style.backgroundColor = "salmon";
		}
		//event.preventDefault();
		setSubmissionPublic({
			...submissionPublic,
			name: e.target.value,
		});
	}

	function changeEmail(e) {
		const emailCheck =
			/([A-Za-z0-9])+@([A-Za-z0-9])+\.([A-Za-z0-9])+/;
		if (emailCheck.test(e.target.value)) {
			if (!doneEmail) {
				addToValues(CountValues + 1);
				setDoneEmail(true);
			}
			console.log(CountValues);
			e.target.style.backgroundColor = "lightgreen";
		} else if (e.target.value === "") {
			e.target.style.backgroundColor = "white";
		} else {
			e.target.style.backgroundColor = "salmon";
		}
		//event.preventDefault();
		setSubmissionPublic({
			...submissionPublic,
			email: e.target.value,
		});
	}

	function changeTwoCountry(e) {
		const nameCheck = /(^[A-Za-z ]+$)/;
		if (nameCheck.test(e.target.value)) {
			if (!doneCountry) {
				addToValues(CountValues + 1);
				setDoneCountry(true);
			}
			console.log(CountValues);
			e.target.style.backgroundColor = "lightgreen";
		} else if (e.target.value === "") {
			e.target.style.backgroundColor = "white";
		} else {
			e.target.style.backgroundColor = "salmon";
		}
		//event.preventDefault();
		setSubmissionPublic({
			...submissionPublic,
			countryTwo: e.target.value,
		});
	}

	function changeCity(e) {
		const nameCheck = /(^[A-Za-z ]+$)/;
		if (nameCheck.test(e.target.value)) {
			if (!doneCity) {
				addToValues(CountValues + 1);
				setDoneCity(true);
			}
			console.log(CountValues);
			e.target.style.backgroundColor = "lightgreen";
		} else if (e.target.value === "") {
			e.target.style.backgroundColor = "white";
		} else {
			e.target.style.backgroundColor = "salmon";
		}
		//event.preventDefault();
		setSubmissionPublic({
			...submissionPublic,
			city: e.target.value,
		});
	}

	function changePhone(e) {
		const phoneCheck = /\d{10,}/;
		if (phoneCheck.test(e.target.value)) {
			if (!donePhone) {
				addToValues(CountValues + 1);
				setDonePhone(true);
			}
			console.log(CountValues);
			e.target.style.backgroundColor = "lightgreen";
		} else if (e.target.value === "") {
			e.target.style.backgroundColor = "white";
		} else {
			e.target.style.backgroundColor = "salmon";
		}
		//event.preventDefault();
		setSubmissionPublic({
			...submissionPublic,
			phone: e.target.value,
		});
	}

	function changeTime(e) {
		const nameCheck = /(^[A-Za-z ]+$)/;
		if (nameCheck.test(e.target.value)) {
			if (!doneTime) {
				addToValues(CountValues + 1);
				setDoneTime(true);
			}
			console.log(CountValues);
			e.target.style.backgroundColor = "lightgreen";
		} else if (e.target.value === "") {
			e.target.style.backgroundColor = "white";
		} else {
			e.target.style.backgroundColor = "salmon";
		}
		//event.preventDefault();
		setSubmissionPublic({
			...submissionPublic,
			time: e.target.value,
		});
	}

	function changeHeard(e) {
		const nameCheck = /(^[A-Za-z ]+$)/;
		if (nameCheck.test(e.target.value)) {
			if (!doneHeard) {
				addToValues(CountValues + 1);
				setDoneHeard(true);
			}
			console.log(CountValues);
			e.target.style.backgroundColor = "lightgreen";
		} else if (e.target.value === "") {
			e.target.style.backgroundColor = "white";
		} else {
			e.target.style.backgroundColor = "salmon";
		}
		//event.preventDefault();
		setSubmissionPublic({
			...submissionPublic,
			heardAbout: e.target.value,
		});
	}

	const countries = [
		{ label: "United States", value: "US" },
		{ label: "United Arab Emirates", value: "AE" },
		{ label: "Afghanistan", value: "AF" },
		{ label: "Antigua & Barbuda", value: "AG" },
		{ label: "Albania", value: "AL" },
		{ label: "Armenia", value: "AM" },
		{ label: "Andorra", value: "AD" },
		{ label: "Angola", value: "AO" },
		{ label: "Antarctica", value: "AQ" },
		{ label: "Argentina", value: "AR" },
		{ label: "American Samoa", value: "AS" },
		{ label: "Austria", value: "AT" },
		{ label: "Australia", value: "AU" },
		{ label: "Aruba", value: "AW" },
		{ label: "Azerbaijan", value: "AZ" },
		{ label: "Bosnia & Herzegovina", value: "BA" },
		{ label: "Barbados", value: "BB" },
		{ label: "Bangladesh", value: "BD" },
		{ label: "Belgium", value: "BE" },
		{ label: "Burkina Faso", value: "BF" },
		{ label: "Bulgaria", value: "BG" },
		{ label: "Bahrain", value: "BH" },
		{ label: "Benin", value: "BJ" },
		{ label: "Bermuda", value: "BM" },
		{ label: "Bolivia", value: "BO" },
		{ label: "Bonaire", value: "BQ" },
		{ label: "Brazil", value: "BR" },
		{ label: "Bahamas", value: "BS" },
		{ label: "Bhutan", value: "BT" },
		{ label: "Botswana", value: "BW" },
		{ label: "Belarus", value: "BY" },
		{ label: "Belize", value: "BZ" },
		{ label: "Canada", value: "CA" },
		{ label: "Cocos (Keeling) Islands", value: "CC" },
		{ label: "Congo (Dem. Rep.)", value: "CD" },
		{ label: "Central African Rep.", value: "CF" },
		{ label: "Congo (Rep.)", value: "CG" },
		{ label: "Switzerland", value: "CH" },
		{ label: "Cote d Ivoire", value: "CI" },
		{ label: "Chile", value: "CL" },
		{ label: "Cameroon", value: "CM" },
		{ label: "China", value: "CN" },
		{ label: "Costa Rica", value: "CR" },
		{ label: "Curacao", value: "CW" },
		{ label: "Cyprus", value: "CY" },
		{ label: "Czech Republic", value: "CZ" },
		{ label: "Germany", value: "DE" },
		{ label: "Denmark", value: "DK" },
		{ label: "Dominican Republic", value: "DO" },
		{ label: "Algeria", value: "DZ" },
		{ label: "Ecuador", value: "EC" },
		{ label: "Estonia", value: "EE" },
		{ label: "Egypt", value: "EG" },
		{ label: "Spain", value: "ES" },
		{ label: "Ethiopia", value: "ET" },
		{ label: "Finland", value: "FI" },
		{ label: "Fiji", value: "FJ" },
		{ label: "Micronesia", value: "FM" },
		{ label: "Faeroe Islands", value: "FO" },
		{ label: "France", value: "FR" },
		{ label: "Gabon", value: "GA" },
		{ label: "United Kingdom", value: "GB" },
		{ label: "Grenada", value: "GD" },
		{ label: "Georgia", value: "GE" },
		{ label: "French Guiana", value: "GF" },
		{ label: "Ghana", value: "GH" },
		{ label: "Gibraltar", value: "GI" },
		{ label: "Gambia", value: "GM" },
		{ label: "Guinea", value: "GN" },
		{ label: "Guadeloupe", value: "GP" },
		{ label: "Equatorial Guinea", value: "GQ" },
		{ label: "Greece", value: "GR" },
		{
			label: "South Georgia & the South Sandwich Islands",
			value: "GS",
		},
		{ label: "Guatemala", value: "GT" },
		{ label: "Guam", value: "GU" },
		{ label: "Guyana", value: "GY" },
		{ label: "Hong Kong", value: "HK" },
		{ label: "Honduras", value: "HN" },
		{ label: "Croatia", value: "HR" },
		{ label: "Haiti", value: "HT" },
		{ label: "Hungary", value: "HU" },
		{ label: "Indonesia", value: "ID" },
		{ label: "Ireland", value: "IE" },
		{ label: "Israel", value: "IL" },
		{ label: "India", value: "IN" },
		{ label: "Iraq", value: "IQ" },
		{ label: "Iceland", value: "IS" },
		{ label: "Italy", value: "IT" },
		{ label: "Jamaica", value: "JM" },
		{ label: "Jordan", value: "JO" },
		{ label: "Japan", value: "JP" },
		{ label: "Kenya", value: "KE" },
		{ label: "Cambodia", value: "KH" },
		{ label: "Comoros", value: "KM" },
		{ label: "St. Kitts & Nevis", value: "KN" },
		{ label: "Korea (South)", value: "KR" },
		{ label: "Kuwait", value: "KW" },
		{ label: "Cayman Islands", value: "KY" },
		{ label: "Kazakhstan", value: "KZ" },
		{ label: "Laos", value: "LA" },
		{ label: "Lebanon", value: "LB" },
		{ label: "St. Lucia", value: "LC" },
		{ label: "Liechtenstein", value: "LI" },
		{ label: "Sri Lanka", value: "LK" },
		{ label: "Lesotho", value: "LS" },
		{ label: "Lithuania", value: "LT" },
		{ label: "Luxembourg", value: "LU" },
		{ label: "Latvia", value: "LV" },
		{ label: "Morocco", value: "MA" },
		{ label: "Monaco", value: "MC" },
		{ label: "Moldova", value: "MD" },
		{ label: "Montenegro", value: "ME" },
		{ label: "Saint Martin", value: "MF" },
		{ label: "Madagascar", value: "MG" },
		{ label: "Macedonia", value: "MK" },
		{ label: "Mongolia", value: "MN" },
		{ label: "Macau", value: "MO" },
		{ label: "Martinique", value: "MQ" },
		{ label: "Mauritania", value: "MR" },
		{ label: "Malta", value: "MT" },
		{ label: "Mauritius", value: "MU" },
		{ label: "Maldives", value: "MV" },
		{ label: "Malawi", value: "MW" },
		{ label: "Mexico", value: "MX" },
		{ label: "Malaysia", value: "MY" },
		{ label: "Mozambique", value: "MZ" },
		{ label: "Namibia", value: "NA" },
		{ label: "New Caledonia", value: "NC" },
		{ label: "Nigeria", value: "NG" },
		{ label: "Nicaragua", value: "NI" },
		{ label: "Netherlands", value: "NL" },
		{ label: "Norway", value: "NO" },
		{ label: "Nepal", value: "NP" },
		{ label: "New Zealand", value: "NZ" },
		{ label: "Oman", value: "OM" },
		{ label: "Panama", value: "PA" },
		{ label: "Peru", value: "PE" },
		{ label: "French Polynesia", value: "PF" },
		{ label: "Papua New Guinea", value: "PG" },
		{ label: "Philippines", value: "PH" },
		{ label: "Pakistan", value: "PK" },
		{ label: "Poland", value: "PL" },
		{ label: "Pitcairn", value: "PN" },
		{ label: "Puerto Rico", value: "PR" },
		{ label: "Palestine", value: "PS" },
		{ label: "Portugal", value: "PT" },
		{ label: "Paraguay", value: "PY" },
		{ label: "Qatar", value: "QA" },
		{ label: "Reunion", value: "RE" },
		{ label: "Romania", value: "RO" },
		{ label: "Serbia", value: "RS" },
		{ label: "Rwanda", value: "RW" },
		{ label: "Saudi Arabia", value: "SA" },
		{ label: "Seychelles", value: "SC" },
		{ label: "Sudan", value: "SD" },
		{ label: "Sweden", value: "SE" },
		{ label: "Singapore", value: "SG" },
		{ label: "Slovenia", value: "SI" },
		{ label: "Slovakia", value: "SK" },
		{ label: "Sierra Leone", value: "SL" },
		{ label: "Senegal", value: "SN" },
		{ label: "Suriname", value: "SR" },
		{ label: "El Salvador", value: "SV" },
		{ label: "Sint Maarten", value: "SX" },
		{ label: "Syria", value: "SY" },
		{ label: "Swaziland", value: "SZ" },
		{ label: "Chad", value: "TD" },
		{ label: "Togo", value: "TG" },
		{ label: "Thailand", value: "TH" },
		{ label: "Tajikistan", value: "TJ" },
		{ label: "Timor-Leste", value: "TL" },
		{ label: "Tunisia", value: "TN" },
		{ label: "Turkey", value: "TR" },
		{ label: "Trinidad & Tobago", value: "TT" },
		{ label: "Taiwan", value: "TW" },
		{ label: "Tanzania", value: "TZ" },
		{ label: "Uganda", value: "UG" },
		{ label: "US minor outlying islands", value: "UM" },
		{ label: "Uruguay", value: "UY" },
		{ label: "St. Vincent", value: "VC" },
		{ label: "Venezuela", value: "VE" },
		{ label: "Virgin Islands (UK)", value: "VG" },
		{ label: "Virgin Islands (US)", value: "VI" },
		{ label: "Vietnam", value: "VN" },
		{ label: "Wallis & Futuna", value: "WF" },
		{ label: "Kosovo", value: "XK" },
		{ label: "Yemen", value: "YE" },
		{ label: "Mayotte", value: "YT" },
		{ label: "South Africa", value: "ZA" },
		{ label: "Zambia", value: "ZM" },
		{ label: "Zimbabwe", value: "ZW" },
	];
	const languages = [
		{ label: "English", value: "en" },
		{ label: "Français", value: "fr" },
		{ label: "Español", value: "es" },
		{ label: "Deutsch", value: "de" },
		{ label: "Italiano", value: "it" },
		{ label: "Polski", value: "pl" },
		{ label: "عربي", value: "ar" },
	];

	function changeCountry(e) {
		//event.preventDefault();
		setSubmission({
			...submission,
			country: e.target.value,
		});
	}

	function changePublicCountry(e) {
		//event.preventDefault();
		setSubmission({
			...submission,
			country: e.target.value,
		});
		var newLinks = "https://shield.igenius.biz/join.dhtml?action=entype&language=EN&sponsor=475398&setform=kuvera_enrollment_forretail_step1.html&setflagname=apexww&setflag=N&mtype=2&inenrollorder=PAT&country=" + e.target.value;
		newCountry(newLinks);
		console.log(publicEnroll_link);
		console.log(submission);
	}

	function sendToSite(e) {
		e.preventDefault();
		window.location = publicEnroll_link;
	}
	function changeLanguage(e) {
		//event.preventDefault();
		setSubmission({
			...submission,
			language: e.target.value,
		});
	}

	function SendToTeam(e) {
		e.preventDefault();
		if (!window.sponsor) {
			alert("You must find a distributor to enroll under.");
			return;
		}
		let vars = structuredClone(submission);
		vars.sponsor = window.sponsor;
		var url =
			"https://shield.igenius.biz/join.dhtml?" +
			Object.keys(vars)
				.map((key) => key + "=" + vars[key])
				.join("&");
		console.log("URL", url);
		window.location = url;
		console.log(url);
	}

	function SendInForm(e) {
		setLoaderGif("block");
		addToValues(0);
		e.preventDefault();
		var url = "/scripts/newSubmission.php";
		axios.post(
			url,
			{
				data: submissionPublic,
			},
			{ headers: { "Content-Type": "application/json" } }
		).then((response) => {
			console.log(response);
			var newR = response.data.success;
			if (newR === "Yes") {
				setLoaderGif("none");
				alert(
					"Thank you for your submission you will be contacted within the next 48 hours!"
				);
				setSubmissionPublic({
					name: "",
					email: "",
					countryTwo: "",
					city: "",
					phone: "",
					time: "",
					heardAbout: "",
				});
				SetAllisDone();
				setIsSubModalOpen(false);
			} else {
				setLoaderGif("none");
				alert(
					"Could not submit at this time. Please try again."
				);
				setSubmissionPublic({
					name: "",
					email: "",
					countryTwo: "",
					city: "",
					phone: "",
					time: "",
					heardAbout: "",
				});
				SetAllisDone();
				setIsSubModalOpen(false);
			}
		});
	}

	return (
		<StyledDiv>
			{window.replicated ? (
				<div className="modal">
					<div className="modal-content-Sub">
						<div
							style={{
								textAlign: "right",
							}}>
							<span
								className="closeNew"
								onClick={() =>
									setIsSubModalOpen(
										false
									)
								}>
								&times;
							</span>
						</div>
						<div className="form-submission">
							<form
								onSubmit={
									SendToTeam
								}>
								<h2>
									Enroll
									As An
									iGenius
									Customer
								</h2>
								
								<h2>
									Select
									Country
								</h2>
								<select
									value={
										submission.country
									}
									onChange={
										changeCountry
									}
									name="country"
									required>
									{countries.map(
										(
											option,
											index
										) => {
											return (
												<option
													value={
														option.value
													}
													key={
														option.value
													}>
													{
														option.label
													}
												</option>
											);
										}
									)}
								</select>
								<h2>
									Select
									Language
								</h2>
								<select
									value={
										submission.language
									}
									onChange={
										changeLanguage
									}
									name="country"
									required>
									{languages.map(
										(
											option,
											index
										) => {
											return (
												<option
													value={
														option.value
													}
													key={
														option.value
													}>
													{
														option.label
													}
												</option>
											);
										}
									)}
								</select>
								<br />
								<br />
								<div
									style={{
										textAlign: "center",
									}}>
									<input
										type="submit"
										name="submit"
										value="Submit"
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			) : (
				<div className="modal" style={{overflow: 'auto'}}>
					<div className="modal-content-Sub">
						<div
							id="gifLoaderCheck"
							style={{
								display: gifLoad,
							}}>
							<img
								src={loadingGif}
								alt=""
							/>
						</div>
						<div
							style={{
								textAlign: "right",
							}}>
							<span
								className="closeNew"
								onClick={() => [
									setIsSubModalOpen(
										false
									),
									console.log(
										"this should empty"
									),
									flushValues(),
								]}>
								&times;
							</span>
						</div>
						<div className="form-submission">
							
							<form
								onSubmit={
									SendInForm
								}>
								<h2>
									Ready to learn more? Enter your info and someone
									from our team will reach out to you shortly.{" "}
								</h2>
								<p>
									{" "}
									<input
										placeholder="Name"
										type="text"
										name="Name"
										value={
											submissionPublic.name
										}
										onChange={
											changeName
										}
									/>
								</p>
								<p>
									{" "}
									<input
										placeholder="Email"
										type="text"
										name="Email"
										value={
											submissionPublic.email
										}
										onChange={
											changeEmail
										}
									/>
								</p>
								<p>
									{" "}
									<input
										placeholder="Country"
										type="text"
										name="CountryTwo"
										value={
											submissionPublic.countryTwo
										}
										onChange={
											changeTwoCountry
										}
									/>
								</p>
								<p>
									{" "}
									<input
										placeholder="City"
										type="text"
										name="City"
										value={
											submissionPublic.city
										}
										onChange={
											changeCity
										}
									/>
								</p>
								<p>
									{" "}
									<input
										placeholder="Phone Number"
										name="Phone"
										type="text"
										value={
											submissionPublic.phone
										}
										onChange={
											changePhone
										}
									/>
								</p>
								<p>
									{" "}
									<input
										placeholder="Time to Contact "
										name="Time"
										type="text"
										value={
											submissionPublic.time
										}
										onChange={
											changeTime
										}
									/>
								</p>
								<p>
									{" "}
									<input
										placeholder="How did you hear about iGenius?"
										name="Heard"
										type="text"
										value={
											submissionPublic.heardAbout
										}
										onChange={
											changeHeard
										}
									/>
								</p>{" "}
								<br />
								<div
									style={{
										textAlign: "center",
									}}>
									<input
										disabled={
											CountValues <
												7
												? true
												: false
										}
										type="submit"
										name="submit"
										value="Submit"
									/>
								</div>
							</form>
							
							
							<br />
							
							<h2>Ready to get started now? Join now with the button below.</h2>
							<form className="publicGetStarted" onSubmit={sendToSite}>

							
							<select 
									value={
										submission.country
									}
									
									onChange={
										changePublicCountry
									}
									name="countryThree"
									required>
									{countries.map(
										(
											option,
											index
										) => {
											return (
												<option
													value={
														option.value
													}
													key={
														option.value
													}>
													{
														option.label
													}
												</option>
											);
										}
									)}
								</select>
								<br /><br />
								<input
										type="submit"
										name="submit"
										value="Submit"
									/>
								</form>							
							
							
							
						</div>
					</div>
				</div>
			)}
		</StyledDiv>
	);
};

export default SubmissionModal;

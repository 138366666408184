import React from "react";
import styled from "styled-components";
import BottomBanner from "../../Sections/BottomBanner/BottomBanner";
import bg from "../../images/igCaresBottom.png";
import { breakpoints } from "../../utils/breakpoints";
import img1 from "../../images/iGeniusImg-1.png";
import img2 from "../../images/iGeniusImg-2.png";
import img3 from "../../images/iGeniusImg-3.png";
import img4 from "../../images/iGenius-KAH.png";
import { motion } from "framer-motion";

// banner video
import bannerVid from "../../videos/iGenius Cares Website Banner.mp4";
import TopBannerVideo from "../../Sections/TopBanner/TopBannerVideo";

const StyledDiv = styled.div`
	overflow-x: hidden;
	background: #ffffff 0% 0% no-repeat padding-box;

	.videoCares {
		width: 100%;
	}

	.content-container {
		padding: 100px 0;
		display: grid;
		grid-template-columns: 1fr;
		row-gap: 230px;
		align-items: center;
		text-align: left;

		.horizontal-section {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.image-section {
				width: 50vw;
				border-radius: 4px;

				img {
					width: 100%;
				}
			}

			.txt-section {
				width: 40%;

				p {
					font-style: normal;
					width: 80%;
					padding: 25px 0;
					margin: 0;
				}

				.section-btn {
					margin-bottom: 25px;
				}
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.content-container {
			padding: 20px 0;
			row-gap: 40px;

			.horizontal-section {
				flex-direction: column;

				.image-section {
					width: 90vw;
				}

				.txt-section {
					width: 90%;
					text-align: center;

					p {
						margin: auto;
					}
				}
			}

			.mid-section {
				flex-direction: column-reverse;
			}

			.heading {
				line-height: 2;
				display: none;
			}
		}
	}
`;

const IGeniusCares = () => {
	return (
		<StyledDiv>
			<TopBannerVideo
				video={bannerVid}
				txt={
					<>
						<b>Our goal</b> is to provide
						services and opportunities
						essential to helping those in
						distress secure lasting and
						balanced success.
					</>
				}
			/>
			<div className="content-container">
				<div className="horizontal-section">
					<div className="image-section">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<img src={img4} alt="" />
						</motion.div>
					</div>
					<div className="txt-section">
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
							<div className="heading">
								Fighting Hunger
							</div>
							<p>
								iGenius Cares is
								teaming up with
								Kids Against
								Hunger to
								provide fully
								nutritious food
								to impoverished
								children and
								families around
								the world...and
								around the
								corner.
							</p>
							<a
								href="https://www.gofundme.com/f/iGenius-Cares"
								target="_blank"
								rel="noreferrer">
								<button className="section-btn">
									Donate
									Now
								</button>
							</a>
						</motion.div>
					</div>
				</div>
				<div className="horizontal-section mid-section">
					<div className="txt-section">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<div className="heading">
							Raising funds for O.U.R.
						</div>
						<p>
							O.U.R. rescues children
							who have been sex
							trafficked and shines a
							light on the horrific
							issue. Please join
							forces with us to help
							save the children. Your
							donations are greatly
							appreciated.
						</p>
						</motion.div>
					</div>
					<div className="image-section">
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<img src={img1} alt="" />
						</motion.div>
					</div>
				</div>
				<div className="horizontal-section">
					<div className="image-section">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<img src={img2} alt="" />
						</motion.div>
					</div>
					<div className="txt-section">
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<div className="heading">
							150 Sack lunches donated
						</div>
						<p>
							iGenius Cares put
							together sack lunches
							for children at the Road
							Home. The Road Home is a
							private non-profit
							social services agency
							that assists individuals
							and families
							experiencing
							homelessness.
						</p>
						</motion.div>
					</div>
				</div>
				<div className="horizontal-section mid-section">
					<div className="txt-section">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<div className="heading">
							1,000 hygiene kits
							assembled
						</div>
						<p>
							iGenius Cares purchased
							supplies from Clean the
							World and assembled over
							1,000 humanitarian kits.
							The kits were donated to
							shelters for women and
							children who are victims
							of abuse.
						</p>
						</motion.div>
					</div>
					<div className="image-section">
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<img src={img3} alt="" />
						</motion.div>
					</div>
				</div>
			</div>
			<BottomBanner
				bg={bg}
				txt={
					"The iGenius Cares mission is to make a measurable impact on those in need and provide them opportunities to plan for a self-sustainable future."
				}
				btn={
					<>
						<a
							href="https://www.gofundme.com/f/iGenius-Cares"
							target="_blank"
							rel="noreferrer">
							<button className="section-btn">
								Donate Now
							</button>
						</a>
					</>
				}
			/>
		</StyledDiv>
	);
};

export default IGeniusCares;
